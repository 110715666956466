var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{staticClass:"table-assistant-profile mt-2",attrs:{"responsive":""}},[_c('b-tbody',[(_vm.assistant_profiles.length > 0)?[_vm._l((_vm.assistant_profiles),function(assistant_profile,index){return [_c('b-tr',{key:'assistant_header' + assistant_profile.id},[_c('b-th',{attrs:{"colspan":"10","rowspan":"1"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"flex-grow-1 text-center"},[_vm._v(" PERFIL AYUDANTE PARA LA "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ).toUpperCase())+" "+_vm._s(index + 1)+" ")]),_c('div',[(
                      index == 0 &&
                      _vm.allows_crud &&
                      _vm.oldMatter &&
                      !_vm.oldMatter.is_closed
                    )?_c('ButtonSmall',{class:`ml-1`,attrs:{"tooltip_text":`Agregar un nuevo Perfil del Ayudante`,"click_button":_vm.createAssistantProfileBase,"icon":'plus',"variant":'none'}}):_vm._e(),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('ButtonSmall',{class:`ml-1`,attrs:{"click_button":() => _vm.deleteAssistantProfile(assistant_profile),"tooltip_text":`Eliminar el Perfil del Ayudante`,"icon":'trash'}}):_vm._e()],1)])])],1),_c('b-tr',{key:'assistant_profile' + assistant_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v("Perfil Ayudante:")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(assistant_profile.profile)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                            _vm.$bvModal.show(
                              `edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                            ),"icon":'square'}}),(assistant_profile.profile)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearAssistantProfileChild(assistant_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`,"title":"Editar Perfil Ayudante:","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":assistant_profile,"Text":assistant_profile.profile,"permit_blank":true},on:{"save":_vm.updateAssistantProfileChild,"close":function($event){return _vm.$bvModal.hide(
                            `edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                          )}}})],1)]:_vm._e()]],2)])],1),_c('b-tr',{key:'assistant_role' + assistant_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v("Rol del Ayudante:")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(assistant_profile.role)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                            _vm.$bvModal.show(
                              `edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                            ),"icon":'square'}}),(assistant_profile.role)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearAssistantRole(assistant_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`,"title":"Editar Perfil Ayudante:","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":assistant_profile,"Text":assistant_profile.role,"permit_blank":true},on:{"save":_vm.updateAssistantRole,"close":function($event){return _vm.$bvModal.hide(
                            `edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                          )}}})],1)]:_vm._e()]],2)])],1)]})]:(_vm.allows_crud)?[[_c('b-tr',[_c('b-th',{attrs:{"colspan":"10","rowspan":"1"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"flex-grow-1 text-center"},[_vm._v(" PERFIL AYUDANTE PARA LA "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ).toUpperCase())+" ")]),_c('div',[(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('ButtonSmall',{class:`mr-1`,attrs:{"tooltip_text":`Agregar un nuevo Perfil del Ayudante`,"click_button":_vm.createAssistantProfileBase,"icon":'plus',"variant":'none'}}):_vm._e()],1)])])],1)]]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }