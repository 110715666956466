<template>
  <div>
    <b-table-simple responsive class="table-assistant-profile mt-2">
      <b-tbody>
        <template v-if="assistant_profiles.length > 0">
          <template v-for="(assistant_profile, index) in assistant_profiles">
            <b-tr :key="'assistant_header' + assistant_profile.id">
              <b-th colspan="10" rowspan="1">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="flex-grow-1 text-center">
                    PERFIL AYUDANTE PARA LA
                    {{
                      $getVisibleNames(
                        "mesh.egressprofilematter",
                        false,
                        "Asignatura"
                      ).toUpperCase()
                    }}
                    {{ index + 1 }}
                  </span>
                  <div>
                    <ButtonSmall
                      v-if="
                        index == 0 &&
                        allows_crud &&
                        oldMatter &&
                        !oldMatter.is_closed
                      "
                      :tooltip_text="`Agregar un nuevo Perfil del Ayudante`"
                      :click_button="createAssistantProfileBase"
                      :class="`ml-1`"
                      :icon="'plus'"
                      :variant="'none'"
                    >
                    </ButtonSmall>
                    <ButtonSmall
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                      :click_button="
                        () => deleteAssistantProfile(assistant_profile)
                      "
                      :tooltip_text="`Eliminar el Perfil del Ayudante`"
                      :class="`ml-1`"
                      :icon="'trash'"
                    ></ButtonSmall>
                  </div>
                </div>
              </b-th>
            </b-tr>
            <b-tr :key="'assistant_profile' + assistant_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>Perfil Ayudante:</strong>
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <template>
                    <div
                      class="wrapper2 rich-text-content w-100"
                      v-html="assistant_profile.profile"
                    ></div>
                    <template
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                    >
                      <div class="d-flex align-content-center p-1">
                        <ButtonSmall
                          tabindex="-1"
                          :tooltip_text="`Editar`"
                          :click_button="
                            () =>
                              $bvModal.show(
                                `edit-assistant_profile-modal-${assistant_profile.id}-${matter_form_view}`
                              )
                          "
                          :class="``"
                          :icon="'square'"
                        ></ButtonSmall>
                        <ButtonSmall
                          v-if="assistant_profile.profile"
                          tabindex="-1"
                          :tooltip_text="`Limpiar`"
                          :click_button="
                            () => clearAssistantProfileChild(assistant_profile)
                          "
                          :class="`ml-1`"
                          :icon="'clean'"
                        ></ButtonSmall>
                      </div>
                      <b-modal
                        :id="`edit-assistant_profile-modal-${assistant_profile.id}-${matter_form_view}`"
                        title="Editar Perfil Ayudante:"
                        size="lg"
                        hide-footer
                        ignore-enforce-focus-selector="#sidebar-right"
                      >
                        <NewRichTextEditor
                          :Object="assistant_profile"
                          :Text="assistant_profile.profile"
                          :permit_blank="true"
                          @save="updateAssistantProfileChild"
                          @close="
                            $bvModal.hide(
                              `edit-assistant_profile-modal-${assistant_profile.id}-${matter_form_view}`
                            )
                          "
                        ></NewRichTextEditor>
                      </b-modal>
                    </template>
                  </template>
                </div>
              </b-td>
            </b-tr>
            <b-tr :key="'assistant_role' + assistant_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>Rol del Ayudante:</strong>
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <template>
                    <div
                      class="wrapper2 rich-text-content w-100"
                      v-html="assistant_profile.role"
                    ></div>
                    <template
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                    >
                      <div class="d-flex align-content-center p-1">
                        <ButtonSmall
                          tabindex="-1"
                          :tooltip_text="`Editar`"
                          :click_button="
                            () =>
                              $bvModal.show(
                                `edit-assistant_role-modal-${assistant_profile.id}-${matter_form_view}`
                              )
                          "
                          :class="``"
                          :icon="'square'"
                        ></ButtonSmall>
                        <ButtonSmall
                          v-if="assistant_profile.role"
                          tabindex="-1"
                          :tooltip_text="`Limpiar`"
                          :click_button="
                            () => clearAssistantRole(assistant_profile)
                          "
                          :class="`ml-1`"
                          :icon="'clean'"
                        ></ButtonSmall>
                      </div>
                      <b-modal
                        :id="`edit-assistant_role-modal-${assistant_profile.id}-${matter_form_view}`"
                        title="Editar Perfil Ayudante:"
                        size="lg"
                        hide-footer
                        ignore-enforce-focus-selector="#sidebar-right"
                      >
                        <NewRichTextEditor
                          :Object="assistant_profile"
                          :Text="assistant_profile.role"
                          :permit_blank="true"
                          @save="updateAssistantRole"
                          @close="
                            $bvModal.hide(
                              `edit-assistant_role-modal-${assistant_profile.id}-${matter_form_view}`
                            )
                          "
                        ></NewRichTextEditor>
                      </b-modal>
                    </template>
                  </template>
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else-if="allows_crud">
          <template>
            <b-tr>
              <b-th colspan="10" rowspan="1">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="flex-grow-1 text-center">
                    PERFIL AYUDANTE PARA LA
                    {{
                      $getVisibleNames(
                        "mesh.egressprofilematter",
                        false,
                        "Asignatura"
                      ).toUpperCase()
                    }}
                  </span>
                  <div>
                    <ButtonSmall
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                      :tooltip_text="`Agregar un nuevo Perfil del Ayudante`"
                      :click_button="createAssistantProfileBase"
                      :class="`mr-1`"
                      :icon="'plus'"
                      :variant="'none'"
                    >
                    </ButtonSmall>
                  </div>
                </div>
              </b-th>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "AssistantProfileComponent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    matter_form_view: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetch_finish: false,
      assistant_profiles: [],
    };
  },
  methods: {
    fetchAssistantProfile() {
      if (this.oldMatter) {
        this.$restful
          .Get(`/teaching/assistant-profile/?matter=${this.oldMatter.id}`)
          .then((response) => {
            this.assistant_profiles = response;
            this.fetch_finish = true;
            // if (response.length == 0 && this.allows_crud)
            //   this.createAssistantProfileBase();
          });
      }
    },
    createAssistantProfileBase() {
      this.createAssistantProfile({
        profile: "",
        role: "",
      });
    },
    createAssistantProfile(assistant_profile) {
      if (this.oldMatter.id) {
        assistant_profile.matter = this.oldMatter.id;
        this.$restful
          .Post(`/teaching/assistant-profile/`, assistant_profile)
          .then((response) => {
            this.assistant_profiles.push(response);
            this.$emit("change_assistant_profile");
            toast("Perfil del Asistente creado.");
          });
      }
    },
    updateAssistantProfile(assistant_profile) {
      this.$restful
        .Put(
          `/teaching/assistant-profile/${assistant_profile.id}/`,
          assistant_profile
        )
        .then((response) => {
          if (response) {
            this.$emit("change_assistant_profile");
            this.mutateAssistantProfile(response);
            toast("Perfil del Ayudante actualizado.");
          }
        });
    },
    clearAssistantProfileChild(assistant_profile) {
      assistant_profile.profile = "";
      this.updateAssistantProfile(assistant_profile);
    },
    clearAssistantRole(assistant_profile) {
      assistant_profile.role = "";
      this.updateAssistantProfile(assistant_profile);
    },
    updateAssistantProfileChild(assistant_profile, text) {
      assistant_profile.profile = text;
      this.saveAssistantProfile(assistant_profile);
    },
    updateAssistantRole(assistant_profile, text) {
      assistant_profile.role = text;
      this.saveAssistantProfile(assistant_profile);
    },
    mutateAssistantProfile(response) {
      const index = this.assistant_profiles.findIndex(
        (x) => x.id == response.id
      );
      if (index != -1) {
        this.assistant_profiles[index].profile = response.profile;
        this.assistant_profiles[index].role = response.role;
      }
    },
    saveAssistantProfile(assistant_profile) {
      if (assistant_profile.id == -1)
        this.createAssistantProfile(assistant_profile);
      else this.updateAssistantProfile(assistant_profile);
    },
    deleteAssistantProfile(assistant_profile) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Perfil del Ayudante?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/assistant-profile/${assistant_profile.id}/`)
            .then(() => {
              const index = this.assistant_profiles.findIndex(
                (x) => x.id == assistant_profile.id
              );
              if (index != -1) {
                this.$emit("change_assistant_profile");
                this.assistant_profiles.splice(index, 1);
                toast("Se eliminó el Perfil del Ayudante.");
              }
            });
        }
      });
    },
  },
  created() {
    this.fetchAssistantProfile();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.table-assistant-profile {
  margin-bottom: 1em;
}
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
.wrapper2 {
  text-align: justify;
  margin: 10px;
}
</style>